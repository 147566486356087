<template>
    <div class="ui-core-countdown-section" data-test="ui-core-countdown-section" :data-now="now" :data-start="start">
        <div v-if="now < start" class="pre-components">
            <template v-for="preComponent in blok.preStartComponents" :key="preComponent._uid">
                <Component :is="preComponent.component" :blok="preComponent" />
            </template>
        </div>
        <div v-else-if="now >= start && now <= end" class="during-components">
            <template v-for="duringComponent in blok.duringComponents" :key="duringComponent._uid">
                <Component :is="duringComponent.component" :blok="duringComponent" />
            </template>
        </div>
        <div v-else-if="now > end" class="pos-components">
            <template v-for="postDateComponent in blok.postDateComponents" :key="postDateComponent._uid">
                <Component :is="postDateComponent.component" :blok="postDateComponent" />
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { ICoreCountdownSection } from '~ui/types/components/Countdown/CoreCountdownSection';
const { blok } = defineProps<{ blok: ICoreCountdownSection }>();
const now = ref(new Date().getTime());
//start date and end date should be in format YYYY-MM-DD H:M OR YYYY-MM-DD H:M:S
//timezone should be in format +/-H:M. e.g +02:30, -12:00, etc.
const start = ref(Date.parse(formatDateTimeString(blok.startDate, blok.timezone)));
//by default sb return 00:00:00 add 23:59:59 till the end of the day
const end = ref(Date.parse(formatDateTimeString(blok.endDate, blok.timezone)) + (23 * 3600 + 59 * 60 + 59) * 1000);
const targetDate = computed(() => (now.value < start ? start : end));
provide('targetDate', targetDate);

watch(
    () => now.value,
    (newVal) => {
        if (newVal >= start && newVal < end) {
            delayTransitionPhaseCheck(end + 1); //the during components will show if now equal end time
        } else if (newVal < start) {
            delayTransitionPhaseCheck(start);
        }
    }
);

if (now.value < start) {
    delayTransitionPhaseCheck(start);
}
if (now.value >= start && now.value < end) {
    delayTransitionPhaseCheck(end + 1); //the during components will show if now equal end time
}

function delayTransitionPhaseCheck(time: number) {
    const secondsToEnd = time - now.value;
    const delayLimit = 7 * 24 * 3600 * 1000; //setTimeout has limit for delay is 2147483647 milliseconds
    setTimeout(
        () => {
            now.value = new Date().getTime();
        },
        secondsToEnd > delayLimit ? delayLimit : secondsToEnd
    );
}

function formatDateTimeString(dateTime: string, timezone: string) {
    //convert date format YYYY-MM-DD H:m:sGMT to YYYY-MM-DDTH:m:sGMT
    return `${dateTime.replace(' ', 'T')}:00.000${timezone}`;
}
</script>

<style scoped lang="postcss"></style>
